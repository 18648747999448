// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-album-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\templates\\album.js" /* webpackChunkName: "component---src-templates-album-js" */),
  "component---src-templates-voorstelling-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\templates\\voorstelling.js" /* webpackChunkName: "component---src-templates-voorstelling-js" */),
  "component---src-pages-circus-of-theater-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\circus_of_theater.js" /* webpackChunkName: "component---src-pages-circus-of-theater-js" */),
  "component---src-pages-circus-or-theater-en-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\circus_or_theater.en.js" /* webpackChunkName: "component---src-pages-circus-or-theater-en-js" */),
  "component---src-pages-circus-ou-theatre-fr-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\circus_ou_theatre.fr.js" /* webpackChunkName: "component---src-pages-circus-ou-theatre-fr-js" */),
  "component---src-pages-contact-en-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-fr-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\contact.fr.js" /* webpackChunkName: "component---src-pages-contact-fr-js" */),
  "component---src-pages-contact-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-en-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-fr-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\index.fr.js" /* webpackChunkName: "component---src-pages-index-fr-js" */),
  "component---src-pages-index-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-performances-en-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\performances.en.js" /* webpackChunkName: "component---src-pages-performances-en-js" */),
  "component---src-pages-spectacles-fr-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\spectacles.fr.js" /* webpackChunkName: "component---src-pages-spectacles-fr-js" */),
  "component---src-pages-tour-and-tickets-en-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\tour_and_tickets.en.js" /* webpackChunkName: "component---src-pages-tour-and-tickets-en-js" */),
  "component---src-pages-tour-en-tickets-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\tour_en_tickets.js" /* webpackChunkName: "component---src-pages-tour-en-tickets-js" */),
  "component---src-pages-tour-et-tickets-fr-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\tour_et_tickets.fr.js" /* webpackChunkName: "component---src-pages-tour-et-tickets-fr-js" */),
  "component---src-pages-voorstellingen-js": () => import("C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\src\\pages\\voorstellingen.js" /* webpackChunkName: "component---src-pages-voorstellingen-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\xampp\\htdocs\\circusronaldo\\mobile2\\hello-world\\.cache\\data.json")

